<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div class="col-md-11">
              <h1>Configure Allowances</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'"
                      :label="'+'"
                      @onClick="Add"
                      class="mr-2" />
            </div>
          </div>
          <div v-if="!UnionJobtitleSalaries.length" class="text-center">
            <v-progress-circular indeterminate
                                 color="primary"></v-progress-circular>
          </div>
          <v-simple-table v-if="UnionJobtitleSalaries.length > 0">
            <template>
              <thead>
                <tr>
                  <!-- <th></th> -->
                  <th>
                    Union Membership
                  </th>
                  <th v-for="(item, index) in UnionJobtitleSalaries[0].unionJobTitleListItems" :key="index">
                    {{item.salaryComponent}}
                  </th>
                  <!-- <th>
            Actions
          </th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item , index) in UnionJobtitleSalaries" :key="index">
                  <!-- <td>
            <input
              type="checkbox"
              class="checkbox"
              :value="{id:item.id}"
              v-model="selected" />
          </td> -->
                  <td><button class="view-button" v-on:click="viewMembership(item)">{{item.unionMembership}}</button></td>
                  <td v-for="(itemSub, index) in item.unionJobTitleListItems" :key="index">
                    {{itemSub.value}}
                  </td>
                  <td>
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'Edit'" @onClick="openEditModal(item)" class="mr-2" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

           <!-- View modal -->
          <Dialog ref="viewModal" :title="'Union Rate'" :subTitle="unionMemberName" :width="600">
            <v-row>
              <!-- <TextField v-model="unionMemberName" :disable="true" :label="'Union Member'" /> -->
              <b>Union Membership: {{unionMemberName}}</b>
              <v-col>
                <v-simple-table>
                  <template>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Salary component
                        </th>
                        <th class="text-left">
                          Frequency
                        </th>
                        <th class="text-left">
                          Amount / Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in unionJobtitle" :key="item.id">
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.frequency}}
                        </td>
                        <td>
                          <p class="hint">{{formData.values[index]}} / {{item.amountLabel}}</p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </Dialog>

          <!-- edit modal -->
          <Dialog ref="editModal" :title="'Edit Union Rate'" :subTitle="unionMemberName" :width="600">
            <v-row>
              <TextField v-model="unionMemberName" :disable="true" :label="'Union Membership'" />
              <v-col>
                <TextField type="date" placeholder="Effective Date" :label="'Effective Date'" v-model="formData.effectiveDate" />
              </v-col>
              <v-col>
                <v-simple-table>
                  <template>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Salary component
                        </th>
                        <th class="text-left">
                          Frequency
                        </th>
                        <th class="text-left">
                          Amount / Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in unionJobtitle" :key="item.id">
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.frequency}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          <v-text-field class="mt-4"
                                        v-model="formData.values[index]"
                                        outlined
                                        hide-details
                                        @blur="valueInputed(item,formData.values[index])"
                                        dense />
                          <p class="hint">{{item.amountLabel}}</p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <template v-slot:footer>
              <div class="d-flex">
                <Button :btnType="'Submit'" :isLoading="isLoading" :label="'Update'" @onClick="dialog = true" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Close'" @onClick="closeEditModal" />
              </div>
            </template>
          </Dialog>
          <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="update()" />
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import TextField from '@/components/ui/form/TextField.vue'
import {
  unionMemberService
} from '@/services'
export default {
  components: {
    Button,
    Dialog,
    ConfirmationDialog,
    TextField
  },
  data () {
    return {
      alertMessage: '',
      dialog: false,
      alert: false,
      isLoading: false,
      alertType: '',
      search: '',
      UnionJobtitleSalaries: [],
      unionJobtitle: [],
      unionMember: '',
      unionMemberName: '',
      formData: {
        values: []
      },
      selected: [],
      id: ''
    }
  },
  computed: {

  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    Add () {
      this.$router.push('/job-title/allowance/add')
    },
    viewMembership (item) {
      this.getUnion(item)
      this.$refs.viewModal.openModal()
    },
    getUnion (item) {
      this.unionMember = item.unionMembershipId
      this.unionMemberName = item.unionMembership
      this.id = item.id
      console.log(item)
      unionMemberService.getUnionJobtitle(item.id).then(result => {
        this.unionJobtitle = result.data
        result.data.forEach(data => {
          this.formData.values.push(data.amount)
          this.selected.push({ id: data.id, value: data.amount })
        })
      }).catch(error => {
        console.log(error)
      })
    },
    openEditModal (item) {
      this.getUnion(item)
      this.$refs.editModal.openModal()
    },
    closeEditModal () {
      this.$refs.editModal.closeModal()
      this.reset()
    },
    valueInputed (item, value) {
      if (!value) return
      let index = null
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === item.id) {
          index = i
          break
        }
      }
      if (index !== null) {
        this.selected.splice(index, 1)
      }
      this.selected.push({ id: item.id, value })
    },
    update () {
      const data = [ ]
      this.selected.forEach(sel => {
        data.push(
          {
            'UnionMembershipId': this.unionMember,
            'SalaryComponentId': sel.id,
            'Amount': sel.value,
            'EffectiveDate': this.formData.effectiveDate
          }
        )
      })
      this.isLoading = true
      unionMemberService.updateJobtitle(data).then(() => {
        this.showAlertMessage('Union Rate Updated ', 'success')
        this.$refs.editModal.closeModal()
      }).catch(() => {
        this.showAlertMessage('Union Rate failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.reset()
      })
    },
    reset () {
      this.selected = []
      this.unionMember = ''
      this.formData.values = []
      this.id = ''
    }
  },
  mounted () {
    unionMemberService.getAllUnionJobTitle().then(result => {
      this.UnionJobtitleSalaries = result.data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.view-button {
  text-align: left;
  border: none;
  color: #0066b2;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.hint {
  font-size: 13px;
  margin-top: 1px;
}

</style>
